import React, { useEffect } from 'react'
import { UserProvider } from './contexts'
import { TranslationProvider } from './contexts/TranslationContext'
import { SettingsProvider } from './contexts/CurrencySettingsContext'
import { ContentfulProvider } from './contexts/ContentfulContext'
import Routes, { RouterProvider } from './pages'
import { QueryProvider, ChakraProvider, ModalProvider } from './providers'
import GoogleAnalyticsWrapper from './components/GoogleAnalyticsWrapper'

function App() {
  useEffect(() => {
    if (window.location.host === 'armenianpeace.am') {
      window.location.replace(window.location.pathname + window.location.search)
    }
  }, [])

  console.log('🔵 Armenian Peace')

  return (
    <ChakraProvider>
      <QueryProvider>
        <RouterProvider>
          <ContentfulProvider>
            <GoogleAnalyticsWrapper>
              <TranslationProvider>
                <SettingsProvider>
                  <UserProvider>
                    <ModalProvider>
                      <Routes />
                    </ModalProvider>
                  </UserProvider>
                </SettingsProvider>
              </TranslationProvider>
            </GoogleAnalyticsWrapper>
          </ContentfulProvider>
        </RouterProvider>
      </QueryProvider>
    </ChakraProvider>
  )
}

export default App
